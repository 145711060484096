import React, { useEffect, useContext } from "react";
import { Helmet } from 'react-helmet';
import styled from "styled-components";
import { Button, Section, Text } from "../components/Core";
import { Container, Col, Row, Accordion, Card } from "react-bootstrap";
import { Link, navigate } from "gatsby";

import TrustBoxMicroCombo from "../components/TrustBoxMiniCombo/TrustBoxMicroCombo";
import HeroSupport from "../components/Rebrand/Hero/HeroSupport";
import chevronRight from "./../assets/image/rebrand/svg/chevron-circle-right.svg"

import chevronUp from "./../assets/image/rebrand/svg/chevron-up-arrow.svg"
import chevronDown from "./../assets/image/rebrand/svg/chevron-down-arrow.svg"
import GlobalContext from '../context/GlobalContext';

const axios = require('axios');

const MainAccordionWrapper = styled.div`
  margin-top: 100px;
  .accordion .card {
    h3 {
      z-index: 9;
    }
  }
  .accordion .card .collapse,
  .accordion .card .collapsing {
    position: relative;
    display: block;
    height: 0;
    transition: height 0.3s ease;
  }
  p, h3 {
    &:hover {
      cursor: pointer;
    }
  }

  .accordion .card .collapse:after,
  .accordion .card .collapsing:after {
    position: absolute;
    display: block;
    content: "";
    background: url(${chevronUp});
    background-size: 100% 100% !important;
    width: 20px;
    height: 20px;
    top: -45px;
    right: 0;
  }

  .accordion .card .collapse.show {
    height: auto;
  }

  .accordion .card .accordion .collapse:after {
    content: none;
  }

  .accordion .card .collapse.show:after {
    background: url(${chevronDown});
  }

  .accordion .card .accordion .card {
    border: none;
    padding: 0;
  }

  .accordion {
    border-bottom: 1px solid #666;

    .card .accordion {
      border-bottom: 0;
    }
  }

  .card {
    border: none;
    border-top: 1px solid #666;
    padding: 10px 0 20px 0;
    border-radius: 0;

    h3 {
      padding-bottom: 0;
    }

    p {
      padding: 0;
      margin: 0 0 10px 0;
    }

    .card-body {
      color: #3b3b3b;
    }
  }
`;

const LinkItem = styled(Link)`
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
  text-align: left;
  font-size: 1.2rem;
  @media (max-width: 991px) { 
    flex: 1 1 calc(100%);
  }
`

const KnowledgeBase = () => {

  const [categories, setCategories] = React.useState([]);
  const [featuredArticles, setFeaturedArticles] = React.useState([]);
  const gContext = useContext(GlobalContext);

  useEffect(() => {
    if (gContext?.faqCategories) {
      setFeaturedArticles(gContext?.faqCategories?.sort((a, b) => a.id - b.id))
    }
  }, [gContext?.faqCategories]);

  useEffect(() => {
    if (gContext.faqArticles) {
      setFeaturedArticles(gContext?.faqArticles?.filter(item => item.featured))
    }
  }, [gContext?.faqArticles]);

  return (
    <>
      <Helmet>
        <title>YouFibre Knowledge base</title>
        <meta name="title" content="YouFibre Knowledge base" />
        <meta name="description" content="YouFibre Knowledge base" />
      </Helmet>
      <HeroSupport />
      <Section>
        <Container>
          <Row className="mb-5">
            <Col sm={12} className="mb-3">
              <h3 className="text-center">Most popular articles</h3>
            </Col>

            {
              featuredArticles.filter(item => item.featured).map((article, index) => {
                return (
                  <Col sm={12} md={3} className="mb-3">
                    <div className="popular-article">
                      <Text className="mb-3">
                        <strong>{article.title}</strong>
                      </Text>
                      <Text>
                        <Link to={`/knowledge-base/article/${article.article_slug}`}>
                          Find out more <img src={chevronRight} width={18} />
                        </Link>
                      </Text>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          
          <MainAccordionWrapper>
            <Accordion>
              {
                gContext?.faqCategories?.map((category, index) => {
                  return (
                    <Card key={index+1}>
                      <Accordion.Toggle as={"h3"} eventKey={index+1}>
                        {category.title}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={index+1}>
                        <Card.Body>
                          <Accordion>
                            <Card key={index}>
                              {
                                category?.faq_articles?.map((faq, index) => {
                                  return (
                                    <LinkItem to={`/knowledge-base/article/${faq.article_slug}`}>{faq.title}</LinkItem>
                                  )
                                })
                              }
                            </Card>
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })
              }
            </Accordion>
          </MainAccordionWrapper>
        </Container>
      </Section>
      <Section bg="#04c78d" className="green-bg">
        <Container>
          <Row>
            <Col sm={12} className="text-center">
              <h2>No, we love you more.</h2>
              <Button className="medium-button light-bg mt-2 mb-5" onClick={() => navigate('https://www.youfibre.com/reviews/')}>
                View reviews
              </Button>
              <TrustBoxMicroCombo />
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}
export default KnowledgeBase